/* eslint-disable no-unused-vars */
import React from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
// import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

function StepFive({
  isSubmitting,
  values,
  setFieldValue,
  touched,
  errors,
  initialValues,
}) {
  return (
    <>
      <div className="card card-dark p-3 m-3">
        <div className="card-header">
          <h3 className="card-title">Bank Details</h3>
        </div>
        <Box className="card-body" sx={{ padding: "15px 0" }}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Box
                      sx={{
                        border: "1px solid grey",
                        borderRadius: "8px",
                        p: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="left">
                        Bank Details
                      </Typography>
                      <FieldArray name="Payment_Milestones">
                        {(arrayHelpers) => (
                          <div>
                            <Grid
                              container
                              spacing={2}
                              key={values.Bank_Details.id}
                              sx={{ my: 1 }}
                            >
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={1}></Grid>

                                  <Grid item xs={5}>
                                    <Field
                                      fullWidth
                                      label="A/C Holder Name"
                                      name={`Bank_Details.account_holder_name`}
                                      value={
                                        values.Bank_Details.account_holder_name
                                      }
                                      variant="outlined"
                                      as={TextField}
                                      error={
                                        touched.Bank_Details
                                          ?.account_holder_name &&
                                        Boolean(
                                          errors.Bank_Details
                                            ?.account_holder_name
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details
                                          ?.account_holder_name &&
                                        errors.Bank_Details?.account_holder_name
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Field
                                      fullWidth
                                      label="A/C Number"
                                      name={`Bank_Details.account_number`}
                                      variant="outlined"
                                      value={values.Bank_Details.account_number}
                                      type="number"
                                      as={TextField}
                                      error={
                                        touched.Bank_Details?.account_number &&
                                        Boolean(
                                          errors.Bank_Details?.account_number
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details?.account_number &&
                                        errors.Bank_Details?.account_number
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Select
                                      label="A/C Type"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={values.Bank_Details.account_type}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `Bank_Details.account_type`,
                                          e.target.value
                                        )
                                      }
                                      error={
                                        touched.Bank_Details?.account_type &&
                                        Boolean(
                                          errors.Bank_Details?.account_type
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details?.account_type &&
                                        errors.Bank_Details?.account_type
                                      }
                                    >
                                      <MenuItem value={"Savings"}>Savings</MenuItem>
                                      <MenuItem value={"Current"}>Current</MenuItem>
                                    </Select>
                                  </Grid>
                                  
                                  <Grid item xs={3}>
                                    <Field
                                      fullWidth
                                      label="IFSC Number"
                                      name={`Bank_Details.ifsc_code`}
                                      variant="outlined"
                                      value={values.Bank_Details.ifsc_code}
                                      type="text"
                                      as={TextField}
                                      error={
                                        touched.Bank_Details?.ifsc_code &&
                                        Boolean(
                                          errors.Bank_Details?.ifsc_code
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details?.ifsc_code &&
                                        errors.Bank_Details?.ifsc_code
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Field
                                      fullWidth
                                      label="SWIFT Number"
                                      name={`Bank_Details.swift_code`}
                                      variant="outlined"
                                      value={values.Bank_Details.swift_code}
                                      type="text"
                                      as={TextField}
                                      error={
                                        touched.Bank_Details?.swift_code &&
                                        Boolean(
                                          errors.Bank_Details?.swift_code
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details?.swift_code &&
                                        errors.Bank_Details?.swift_code
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Field
                                      fullWidth
                                      label="Bank Name"
                                      name={`Bank_Details.bank_name`}
                                      variant="outlined"
                                      value={values.Bank_Details.bank_name}
                                      type="text"
                                      as={TextField}
                                      error={
                                        touched.Bank_Details?.bank_name &&
                                        Boolean(
                                          errors.Bank_Details?.bank_name
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details?.bank_name &&
                                        errors.Bank_Details?.bank_name
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Field
                                      fullWidth
                                      label="Branch Name"
                                      name={`Bank_Details.branch`}
                                      variant="outlined"
                                      value={values.Bank_Details.branch}
                                      type="text"
                                      as={TextField}
                                      error={
                                        touched.Bank_Details?.branch &&
                                        Boolean(
                                          errors.Bank_Details?.branch
                                        )
                                      }
                                      helperText={
                                        touched.Bank_Details?.branch &&
                                        errors.Bank_Details?.branch
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </FieldArray>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default StepFive;
