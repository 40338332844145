/* eslint-disable no-unused-vars */
import React from 'react';
import { FieldArray, ErrorMessage } from 'formik';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export default function StepSeven({ values, setFieldValue, touched, errors }) {
    
    const handleFileUpload = (event, index) => {
        const file = event.currentTarget.files[0];
        const updatedFiles = [...values.uploadedFile];
        updatedFiles[index] = file;
        setFieldValue('uploadedFile', updatedFiles);
    };

    return (
        <>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Upload Documents</h3>
                </div>
                <Box className="card-body" sx={{ padding: "2rem 0" }}>
                    <Grid container spacing={2}>
                        <FieldArray
                            name="uploadedFile"
                            render={arrayHelpers => (
                                <>
                                    {values?.uploadedFile && values?.uploadedFile.length > 0 ? (
                                        values?.uploadedFile?.map((file, index) => (
                                            <Grid container spacing={2} key={index} alignItems="center">
                                                <Grid item md={10} xs={10}>
                                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2, marginBottom: 2 }}>
                                                        <Typography variant="h6" gutterBottom align="left">
                                                            Upload File {index + 1}
                                                        </Typography>
                                                        <input
                                                            type="file"
                                                            onChange={(event) => handleFileUpload(event, index)}
                                                            accept=".pdf,.doc,.docx,.png,.jpg"
                                                        />
                                                        {file && (
                                                            <Typography variant="body2" color="textSecondary">
                                                                {file.name}
                                                            </Typography>
                                                        )}
                                                        <ErrorMessage name={`uploadedFile[${index}]`} component="div" style={{ color: 'red', marginTop: '8px' }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={2} xs={2} display="flex" justifyContent="center" alignItems="center">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => arrayHelpers.remove(index)} // Removes file upload field
                                                        sx={{ padding: 0 }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid container spacing={2}>
                                            <Typography variant="body2" color="textSecondary" sx={{ paddingLeft: 2 }}>
                                                No files uploaded yet.
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <IconButton
                                            aria-label="add"
                                            onClick={() => arrayHelpers.push('')} // Adds a new file upload field
                                            sx={{ padding: 0 }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                </>
                            )}
                        />
                    </Grid>
                </Box>
            </div>
        </>
    );
}
