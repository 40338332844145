/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Dropmenu, Search } from '../../Components';
import { useNavigate, } from "react-router-dom";
import { API_URL } from '../../Config';
import Loading from '../../Components/Loading';
import { useGetPaginatedLeadsQuery } from '../../Redux/Service/LeadApi';


export default function LeadList() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [estimationData, setEstimationData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: Leads, refetch, isLoading, isError, error } = useGetPaginatedLeadsQuery({
        limit: rowsPerPage,
        page: page,
        orderBy: 'DESC',
        search: searchQuery
    });

    const customStyles = {
        rows: {
            style: {
                margin: '0px',
                padding: 0,
                '@media (max-width: 768px)': {
                    padding: '5px 0', // Adjust row padding for smaller screens
                },
            },
        },
        headCells: {
            style: {
                margin: '0px',
                padding: '10px 15px',
                fontWeight: 600,
                fontSize: '15px',
                background: '#f3f3f3',
                '@media (max-width: 768px)': {
                    fontSize: '14px', // Smaller font size for mobile
                    padding: '8px 10px', // Less padding for mobile
                },
            },
        },
        cells: {
            style: {
                margin: '0px',
                padding: '10px 15px',
                fontSize: '15px',
                '@media (max-width: 768px)': {
                    fontSize: '14px', // Smaller font size for mobile
                    padding: '8px 10px', // Less padding for mobile
                },
            },
        },
    };
    
    useEffect(() => {
        refetch();
    }, [refetch, searchQuery]);

    useEffect(() => {
        if (!isLoading && !isError) {
            setEstimationData(Leads?.response);
            setLoading(false);
        }
    }, [isLoading, isError, Leads]);

    const handleEdit = (id) => {
        navigate(`/estimation-edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/estimation/${id}`);
    }

    const handleSearch = (search) => {
        setSearchQuery(search);
        setPage(1);
    }

    const columns = [
        {
            name: 'SL No',
            cell: (row, index) => index + 1,
            width: '60px', // Set a fixed width for SL No
            button: false,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '150px', // Adjust width as needed
        },
        // {
        //     name: 'Client ID',
        //     selector: row => row.clientId,
        //     sortable: true,
        //     width: '120px', // Adjust width as needed
        // },
        {
            name: 'Email',
            selector: row => row.email && row.user?.email,
            sortable: true,
            width: '180px', // Adjust width as needed
        },
        {
            name: 'Phone Number',
            selector: row => row.phone,
            sortable: true,
            width: '120px', // Adjust width as needed
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
            width: '200px', // Adjust width as needed
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: '200px', // Adjust width as needed
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '100px', // Adjust width as needed
        }
    ];
    

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Leads</h1>
                        </div>
                        <div className="">
                            {/* <Link to="/estimate-add" className="btn btn-dark"><DocumentScannerIcon /> Add Estimation</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3 card-outline card-dark">
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>Error: {error?.message}</div>
                ) : (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12} display='flex' justifyContent='flex-end'>
                                <Search onSearch={(query) => handleSearch(query)} />
                            </Grid>
                        </Grid>

                        <DataTable
                            columns={columns}
                            data={estimationData.data}
                            pagination
                            paginationServer
                            paginationTotalRows={estimationData.pagination.total}
                            paginationDefaultPage={page}
                            paginationPerPage={rowsPerPage}
                            onChangePage={setPage}
                            onChangeRowsPerPage={(newPerPage) => {
                                setRowsPerPage(newPerPage);
                                setPage(1);
                            }}
                            customStyles={customStyles}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}