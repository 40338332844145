// Import layouts
import MainLayout from "../Layout/MainLayout";
import AuthLayout from "../AuthLayout/AuthLayout";

// Import pages
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Auth/Login";
import ChangePassword from "../Pages/ChangePassword";
import UserList from "../Pages/User/UserList";
import UserAdd from "../Pages/User/UserAdd";
import UserEdit from "../Pages/User/UserEdit";
import UserView from "../Pages/User/UserView";
import QuotationList from "../Pages/Quotation/QuotationList";
import QuotationAdd from "../Pages/Quotation/QuotationAdd";
import QuotationEdit from "../Pages/Quotation/QuotationEdit";
import QuotationView from "../Pages/Quotation/QuotationView";
import EstimateList from "../Pages/Estimate/EstimateList";
import EstimateAdd from "../Pages/Estimate/EstimateAdd";
import EstimateEdit from "../Pages/Estimate/EstimateEdit";
import EstimateView from "../Pages/Estimate/EstimateView";
import ContractAdd from "../Pages/Contract/ContractAdd";
import ContractList from "../Pages/Contract/ContractList";
import LeadList from "../Pages/Leads/LeadList";

// Route configuration
export const routes = [
  {
    layout: AuthLayout,
    isAuthorized: false,
    path: '/*',
    routes: [
      {
        name: 'Login',
        title: 'Login',
        component: Login,
        path: '/login',
      }
    ]
  },
  {
    layout: MainLayout,
    isAuthorized: true,
    path: '/*',
    routes: [
      {
        name: 'Dashboard',
        title: 'Dashboard',
        component: Dashboard,
        path: '/dashboard',
      },
      {
        name: 'ChangePassword',
        title: 'Change Password',
        component: ChangePassword,
        path: '/profile',
      },
      {
        name: 'UserList',
        title: 'User List',
        component: UserList,
        path: '/user',
      },
      {
        name: 'UserAdd',
        title: 'Add User',
        component: UserAdd,
        path: '/user-add',
      },
      {
        name: 'UserView',
        title: 'View User',
        component: UserView,
        path: '/user/:id',
      },
      {
        name: 'UserEdit',
        title: 'Edit User',
        component: UserEdit,
        path: '/user-edit/:id',
      },
      {
        name: 'QuotationList',
        title: 'Quotation List',
        component: QuotationList,
        path: '/quotation',
      },
      {
        name: 'QuotationAdd',
        title: 'Add Quotation',
        component: QuotationAdd,
        path: '/quotation-add',
      },
      {
        name: 'QuotationEdit',
        title: 'Edit Quotation',
        component: QuotationEdit,
        path: '/quotation-edit/:id',
      },
      {
        name: 'QuotationView',
        title: 'View Quotation',
        component: QuotationView,
        path: '/quotation/:id',
      },
      {
        name: 'EstimateList',
        title: 'Estimate List',
        component: EstimateList,
        path: '/estimate',
      },
      {
        name: 'EstimateAdd',
        title: 'Add Estimate',
        component: EstimateAdd,
        path: '/estimate-add',
      },
      {
        name: 'EstimateEdit',
        title: 'Edit Estimate',
        component: EstimateEdit,
        path: '/estimation-edit/:id',
      },
      {
        name: 'EstimateView',
        title: 'View Estimate',
        component: EstimateView,
        path: '/estimation/:id',
      },
      {
        name: 'ContractAdd',
        title: 'Add Contract',
        component: ContractAdd,
        path: '/contract-add',
      },

      {
        name:'ContractList',
        title:'Contract List',
        component: ContractList,
        path:'/contract'
      },
      {
        name: 'Leads',
        title: 'Leads',
        component: LeadList,
        path: '/leads',
      }
    ]
  }
];
