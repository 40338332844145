/* eslint-disable no-unused-vars */
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import axios from 'axios';
import {API_URL} from "../../Config";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";
import { getAuthToken } from "../../Redux/Service/FetchBaseQuery";

function ContractAdd() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  
  const steps = [
    "Contract Setup",
    "Project Development Time",
    "Technology Stack",
    "Commercial Details",
    "Bank Details",
    "Terms & Conditions",
    "Upload Document",
  ];

  let initialValues = {
    date: new Date(),
    confidential_information: `<b></b>`,
    purpose_of_the_agreement: "",
    scope_of_work: "",
    amendments: "",
    project_development: "",
    deliverable_milestones: [
      {
        id: 1,
        name: "",
        type: "Days",
        delivery: 0,
      },
    ],
    proposed_technology_stack: "",
    team_structure: [
      {
        id: 1,
        member: "",
        engagement_team: "Days",
        no_of_team_members: 0,
      },
    ],
    commercial_details: [
      {
        id: 1,
        description: "",
        amount: 0,
      },
    ],
    Payment_Milestones: [
      {
        id: 1,
        milestone: "",
        Cost: 0,
      },
    ],
    Bank_Details: {
      id: 1,
      bank_name: "",
      account_number: "",
      account_type: "Current",
      ifsc_code: "",
      swift_code: "",
      branch: "",
      account_holder_name: "",
    },
    general_terms_and_conditions: "",
    project_termination_terms: "",
    payment_terms: "",
    application_ownership: "",
    liability: "",
    legal_jurisdiction: "",
    uploadedFile: [],
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("Date is required"),
  });

  const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleSubmit = async (values) => {
    // Create  a new contract formData
    const contractFormData = new FormData();

    // Append the values to the formData
    contractFormData.append('date', values.date);
    contractFormData.append('confidentialInfo', values.confidential_information);
    contractFormData.append('purposeAgreement', values.purpose_of_the_agreement);
    contractFormData.append('scope_of_work', values.scope_of_work);
    contractFormData.append('amendment', values.amendments);
    contractFormData.append('project_development', values.project_development);
    contractFormData.append('technologyStack', values.proposed_technology_stack);
    contractFormData.append('team_structure', JSON.stringify(values.team_structure));
    contractFormData.append('commercial_details', JSON.stringify(values.commercial_details));
    contractFormData.append('Payment_Milestones', JSON.stringify(values.Payment_Milestones));
    contractFormData.append('Bank_Details', JSON.stringify(values.Bank_Details));
    contractFormData.append('general_terms_and_conditions', values.general_terms_and_conditions);
    contractFormData.append('project_termination_terms', values.project_termination_terms);
    contractFormData.append('payment_terms', values.payment_terms);
    contractFormData.append('application_ownership', values.application_ownership);
    contractFormData.append('liability', values.liability);
    contractFormData.append('legal_jurisdiction', values.legal_jurisdiction);

    for (let i = 0; i < values.uploadedFile.length; i++) {
      contractFormData.append(`uploadedFile${i+1}`, values.uploadedFile[i]);
    }

    const token = getAuthToken();

    const response = await axios.post(`${API_URL}contract/authenticate/create`, contractFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });

    console.log("response", response);
  };

  const StepContent = ({
    isSubmitting,
    values,
    setFieldValue,
    touched,
    errors,
    initialValues,
  }) => {
    switch (activeStep) {
      case 0:
        return <StepOne {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      case 1:
        return <StepTwo {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      case 2:
        return <StepThree {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      case 3:
        return <StepFour {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      case 4:
        return <StepFive {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      case 5:
        return <StepSix {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      case 6:
        return <StepSeven {...{ isSubmitting, values, setFieldValue, touched, errors, initialValues }} />;
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-2">
            <h1 className="m-0 badge badge-dark">Add Contract</h1>
            <Link to="/contract/list" className="btn btn-dark">
              <ArrowBackIcon /> Back
            </Link>
          </div>
        </div>
      </div>
      <div className="card card-dark p-3 m-3">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps?.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you're finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={() => setActiveStep(0)}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                  <StepContent
                    {...{
                      isSubmitting,
                      values,
                      setFieldValue,
                      touched,
                      errors,
                      initialValues,
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(activeStep) && (
                      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                      </Button>
                    )}
                    {activeStep === steps?.length - 1 ? (
                      <Button type="submit">
                        Submit
                      </Button>
                    ) : (
                      <Button onClick={handleNext}>Next</Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </div>
    </div>
  );
}

export default ContractAdd;
