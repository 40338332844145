/* eslint-disable no-unused-vars */
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Grid, Box, Typography } from '@mui/material';
import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';

function StepOne({ isSubmitting, values, setFieldValue, touched, errors, initialValues }) {
    // Redux State for Contract Prefilled Data
    const contractPrefilledData = useSelector((state) => state.contractState?.prefilledData?.response);
    console.log("contractPrefilledData", contractPrefilledData);

    // Set Initial Values
    React.useEffect(() => {
        if (contractPrefilledData) {
            setFieldValue('confidential_information', contractPrefilledData[0]?.data?.confidential);
            setFieldValue('purpose_of_the_agreement', contractPrefilledData[1]?.data?.purposeAgreement);
            setFieldValue('amendments', contractPrefilledData[2]?.data?.amendments);
        }
    }, [contractPrefilledData, setFieldValue]);
    return (
        <div className="card card-dark p-3 m-3">
            <div className="card-header">
                <h3 className="card-title">Contract Setup</h3>
            </div>
            <Box className="card-body" sx={{ padding: "15px 0" }}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} align="right">
                                    <CustomDatePicker
                                        label="Date"
                                        name="date"
                                        onChange={(date) => setFieldValue('date', date)}
                                    />
                                    <ErrorMessage name="date" component="div" style={{ color: 'red' }} />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Confidential Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="confidential_information">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('confidential_information', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="confidential_information" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Purpose of the Agreement
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="purpose_of_the_agreement">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('purpose_of_the_agreement', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="purpose_of_the_agreement" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Scope of work
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="scope_of_work">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('scope_of_work', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="scope_of_work" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Amendments
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="amendments">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('amendments', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="amendments" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default StepOne;
