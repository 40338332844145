/* eslint-disable no-unused-vars */
import React from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import { Grid, TextField, Box, Typography } from "@mui/material";
// import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

function StepFour({
  isSubmitting,
  values,
  setFieldValue,
  touched,
  errors,
  initialValues,
}) {
  const handleRemoveItem = (id, arrayHelpers) => {
    arrayHelpers.remove(id);
  };

  return (
    <>
      <div className="card card-dark p-3 m-3">
        <div className="card-header">
          <h3 className="card-title">Comercial Details</h3>
        </div>
        <Box className="card-body" sx={{ padding: "15px 0" }}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Box
                      sx={{
                        border: "1px solid grey",
                        borderRadius: "8px",
                        p: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="left">
                        Comercial Details
                      </Typography>
                      <FieldArray name="commercial_details">
                        {(arrayHelpers) => (
                          <div>
                            {values?.commercial_details?.map((val, index) => (
                              <Grid
                                container
                                spacing={2}
                                key={val.id}
                                sx={{ my: 1 }}
                              >
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                      {index === 0 ? (
                                        <Grid item xs={12}>
                                          <button
                                            className="btn btn-sm"
                                            onClick={() =>
                                              arrayHelpers.push({
                                                id:
                                                  values.commercial_details
                                                    .length + 1,
                                                description: "",
                                                amount: "",
                                              })
                                            }
                                          >
                                            <AddIcon /> Add More
                                          </button>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12}>
                                          <button
                                            className="btn btn-sm"
                                            onClick={() =>
                                              handleRemoveItem(
                                                index,
                                                arrayHelpers
                                              )
                                            }
                                          >
                                            <DeleteIcon /> Delete
                                          </button>
                                        </Grid>
                                      )}
                                    </Grid>

                                    <Grid item xs={1}></Grid>

                                    <Grid item xs={5}>
                                      <Field
                                        fullWidth
                                        label="Description"
                                        name={`commercial_details.${index}.description`}
                                        value={val.description}
                                        variant="outlined"
                                        as={TextField}
                                        error={
                                          touched.commercial_details?.[index]
                                            ?.description &&
                                          Boolean(
                                            errors.commercial_details?.[index]
                                              ?.description
                                          )
                                        }
                                        helperText={
                                          touched.commercial_details?.[index]
                                            ?.description &&
                                          errors.commercial_details?.[index]
                                            ?.description
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <Field
                                        fullWidth
                                        label="Amount (In USD)"
                                        name={`commercial_details.${index}.amount`}
                                        variant="outlined"
                                        type="number"
                                        as={TextField}
                                        error={
                                          touched.commercial_details?.[index]
                                            ?.amount &&
                                          Boolean(
                                            errors.commercial_details?.[index]
                                              ?.amount
                                          )
                                        }
                                        helperText={
                                          touched.commercial_details?.[index]
                                            ?.amount &&
                                          errors.commercial_details?.[index]
                                            ?.amount
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </Box>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Box
                      sx={{
                        border: "1px solid grey",
                        borderRadius: "8px",
                        p: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="left">
                        Payment Milesones
                      </Typography>
                      <FieldArray name="Payment_Milestones">
                        {(arrayHelpers) => (
                          <div>
                            {values?.Payment_Milestones?.map((val, index) => (
                              <Grid
                                container
                                spacing={2}
                                key={val.id}
                                sx={{ my: 1 }}
                              >
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                      {index === 0 ? (
                                        <Grid item xs={12}>
                                          <button
                                            className="btn btn-sm"
                                            onClick={() =>
                                              arrayHelpers.push({
                                                id:
                                                  values.Payment_Milestones
                                                    .length + 1,
                                                milestone: "",
                                                Cost: "",
                                              })
                                            }
                                          >
                                            <AddIcon /> Add More
                                          </button>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12}>
                                          <button
                                            className="btn btn-sm"
                                            onClick={() =>
                                              handleRemoveItem(
                                                index,
                                                arrayHelpers
                                              )
                                            }
                                          >
                                            <DeleteIcon /> Delete
                                          </button>
                                        </Grid>
                                      )}
                                    </Grid>

                                    <Grid item xs={1}></Grid>

                                    <Grid item xs={5}>
                                      <Field
                                        fullWidth
                                        label="Milestone"
                                        name={`Payment_Milestones.${index}.milestone`}
                                        value={val.milestone}
                                        variant="outlined"
                                        as={TextField}
                                        error={
                                          touched.Payment_Milestones?.[index]
                                            ?.milestone &&
                                          Boolean(
                                            errors.Payment_Milestones?.[index]
                                              ?.milestone
                                          )
                                        }
                                        helperText={
                                          touched.Payment_Milestones?.[index]
                                            ?.milestone &&
                                          errors.Payment_Milestones?.[index]
                                            ?.milestone
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <Field
                                        fullWidth
                                        label="Cost (In USD)"
                                        name={`Payment_Milestones.${index}.Cost`}
                                        variant="outlined"
                                        type="number"
                                        as={TextField}
                                        error={
                                          touched.Payment_Milestones?.[index]
                                            ?.Cost &&
                                          Boolean(
                                            errors.Payment_Milestones?.[index]
                                              ?.Cost
                                          )
                                        }
                                        helperText={
                                          touched.Payment_Milestones?.[index]
                                            ?.Cost &&
                                          errors.Payment_Milestones?.[index]
                                            ?.Cost
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default StepFour;
