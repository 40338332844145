import { createSlice } from "@reduxjs/toolkit";

const contractSlice = createSlice({
  name: "contract",
  initialState: {
    prefilledData: null,
  },
  reducers: {
    setContractPrefilledData: (state, action) => {
      state.prefilledData = action.payload;
    },
  },
});

export const { setContractPrefilledData } = contractSlice.actions;

export default contractSlice.reducer;
